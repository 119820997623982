import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { TentTypeV1 } from '@camp67/model';
import { AmenityCompact } from '../amenity';
import { tents } from '../../constants/tents';
import Sprite from '../../sprites/sprite';
import { displayPrice } from '../../state/prices';
import { getBathingPrice, getPrice } from '@camp67/model/booking/price';
import { FormattedString, useMessages } from '../../i18n/useMessages';
import { Tooltip } from '../tooltip';
import { AmplitudeEvent, analyticsState, ButtonTag } from '../../state/amplitude';

export type Operation = 'add' | 'subtract';

interface TentExperienceGalleryItemProps {
  tentType: TentTypeV1;
  onClick?: (type: TentTypeV1, operation: Operation) => void;
  isSelected?: boolean;
  currentCount?: number;
}

export const TentExperienceGalleryItem: React.FC<TentExperienceGalleryItemProps> = ({
  tentType,
  onClick,
  currentCount,
}) => {
  const {
    title: titleKey,
    displayName,
    description: descriptionKey,
    guests: guestsKey,
    gallery,
    amenities,
    isPrivate,
  } = tents[tentType];

  useEffect(() => {
    setImage(0);
  }, [tentType]);

  const getMessage = useMessages();
  const title = getMessage(titleKey);
  const description = getMessage(descriptionKey);
  const guests = getMessage(guestsKey);

  const [image, setImage] = useState(0);
  const basePrice = getPrice(tentType);
  const baseBathingPrice = getBathingPrice(tentType);

  const price = displayPrice({ priceInINR: basePrice });
  const bathingPrice = displayPrice({ priceInINR: baseBathingPrice });

  const onClickAdd = onClick ? () => onClick(tentType, 'add') : null;
  const onClickSubtract = onClick ? () => onClick(tentType, 'subtract') : null;

  const onClickGalleryNext = useCallback(() => {
    setImage((prev) => (prev + 1) % gallery.length);
    analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      screenSource: new URL(window.location.href).pathname,
      buttonTag: ButtonTag.NEXT_IMAGE,
    });
  }, [gallery]);

  const onClickGalleryPrev = useCallback(() => {
    setImage((prev) => (prev === 0 ? gallery.length - 1 : (prev - 1) % gallery.length));
    analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      screenSource: new URL(window.location.href).pathname,
      buttonTag: ButtonTag.PREV_IMAGE,
    });
  }, [gallery]);

  const imageUrl = useMemo(() => gallery[image]?.imageUrl, [image, gallery]);

  return (
    <div
      className="w-full h-[95%] z-20 relative m-auto flex flex-col items-center justify-start rounded-2xl mt-1"
      id="tent-experience-modal-content"
    >
      <div className="w-full relative">
        <img
          alt={`The inside of the ${tentType} tent offered by camp67.`}
          loading="lazy"
          src={imageUrl}
          className="object-cover w-full h-100 sm:h-[440px] md:h-[500px] pointer-events-none select-none"
        />
        <div className="bg-blue-50 w-full absolute bottom-0 flex items-center justify-center px-5 gap-4">
          <button onClick={onClickGalleryPrev} type="button" className="text-black">
            &larr;
          </button>
          <p className="text-black text-center py-3">
            <FormattedString
              message="camp67-reservation.form.step-1.room-gallery"
              components={{
                '1': <>{image + 1}</>,
                '2': <>{gallery.length}</>,
              }}
            />
          </p>
          <button onClick={onClickGalleryNext} type="button" className="text-black">
            &rarr;
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full items-start">
        <hgroup className="w-full">
          <div className="flex flex-row items-center justify-between px-6 py-3 border-b border-b-orange-100 border-dotted">
            <p className="block text-left font-serif text-md text-black font-bold text-nowrap whitespace-nowrap w-full">
              <span className="flex justify-between w-full flex-wrap">
                <span>{displayName || title}</span>
                <span className="font-medium text-right">
                  {price} {getMessage('camp67-accommodation.price-per-guest')}
                </span>
              </span>
              <span className="flex flex-row flex-wrap font-sans items-start mt-0.5 justify-between gap-1.5">
                <span className="flex flex-wrap">
                  <span className="flex mr-1 flex-row gap-1 items-center text-xs opacity-50 font-normal">
                    <Sprite
                      name="person"
                      width="20"
                      height="20"
                      className="w-3.5 min-w-3.5"
                    />
                    {guests}
                  </span>
                  <span
                    className={clsx(
                      'flex flex-row gap-1 items-center text-xs opacity-50 font-normal',
                      {
                        'mr-1': isPrivate,
                      },
                    )}
                  >
                    <Sprite
                      name="complimentary"
                      width="14"
                      height="13"
                      className="w-3.5 min-w-3.5"
                    />
                    {getMessage('camp67-accommodation.price-meals-included')}
                  </span>
                </span>
                <span className="text-xs text-right mt-0.5 text-gray-400 font-normal font-sans">
                  {bathingPrice} {getMessage('camp67-accommodation.price-bathing-dates')}
                </span>
              </span>
              <span className="flex font-sans flex-row gap-1 items-center text-xs opacity-50 font-normal">
                <Sprite
                  name="bathroom"
                  width="14"
                  height="13"
                  className="w-3.5 min-w-3.5"
                />
                {isPrivate
                  ? getMessage('camp67-accommodation.price-bathroom-included')
                  : getMessage('camp67-accommodation.price-shared-bathroom-included')}
              </span>
            </p>
          </div>
        </hgroup>
        <div className="px-6 flex flex-col gap-3 w-full">
          <div className="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 justify-between">
            <p className="font-sans text-md font-normal text-left max-w-100">
              {description}
            </p>
            {onClickAdd && onClickSubtract ? (
              <div className="flex items-center gap-2">
                <span className="text-xs mr-2 text-center">{currentCount}</span>
                <Tooltip
                  text={`${getMessage(
                    'camp67-reservation.form.step-1.summary.rooms.subtract.tooltip',
                  )} ${title}`}
                >
                  <button
                    type="button"
                    className={clsx(
                      'w-10 h-10 bg-[#F9F9F9] hover:bg-[#F1F1F1] rounded-sm transition-all',
                    )}
                    onClick={onClickSubtract}
                    disabled={false}
                  >
                    -
                  </button>
                </Tooltip>
                <Tooltip
                  text={`${getMessage(
                    'camp67-reservation.form.step-1.summary.rooms.add.tooltip',
                  )} ${title}`}
                >
                  <button
                    type="button"
                    className={clsx(
                      'w-10 h-10 bg-green-150 hover:bg-green-200 rounded-sm transition-all',
                    )}
                    onClick={onClickAdd}
                    disabled={false}
                  >
                    +
                  </button>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            {amenities.tentAmenities.map((tentAmenity, index) => (
              <AmenityCompact {...tentAmenity} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
