import React from 'react';

import { TentTypeV1 } from '@camp67/model';
import { images } from '../../constants/images';
import { tents } from '../../constants/tents';
import { useMessages } from '../../i18n/useMessages';
import clsx from 'clsx';
import { analyticsState, AmplitudeEvent, ButtonTag } from '../../state/amplitude';

const rooms = [
  {
    src: images['dorm-singles-v2'],
    alt: 'The dormitory single option offered by camp67.',
    label: tents['dormSingle'].title,
    type: TentTypeV1.DORM_SINGLE,
  },
  {
    src: images['dorm-bunks-v2'],
    alt: 'The dormitory bunk option offered by camp67.',
    label: tents['dormBunk'].title,
    type: TentTypeV1.DORM_BUNK,
  },
  {
    src: images['twin-tent-v2-1'],
    alt: 'The twin bed cottage option offered by camp67.',
    label: tents['twinRoom'].title,
    type: TentTypeV1.TWIN_ROOM,
  },
  {
    src: images['family-tent-v2-1'],
    alt: 'The family cottage option offered by camp67.',
    label: tents['familyRoom'].title,
    type: TentTypeV1.FAMILY_ROOM,
  },
  {
    src: images['family-plus-tent-v2-1'],
    alt: 'The family cottage plus option offered by camp67.',
    label: tents['familyRoomPlus+'].title,
    type: TentTypeV1.FAMILY_ROOM_PLUS,
  },
];

export interface TentExperienceCarouselProps {
  tent: TentTypeV1;
  onTentChange: (type: TentTypeV1) => void;
  countProvider?: (type: TentTypeV1) => number;
}

export const TentExperienceCarousel: React.FC<TentExperienceCarouselProps> = ({
  tent,
  countProvider,
  onTentChange,
}) => {
  const getMessage = useMessages();
  const activeIndex = rooms.findIndex((r) => r.type === tent);

  return (
    <div className="flex flex-col-reverse w-full">
      <div className="flex w-full">
        {rooms.map((room, key) => {
          const count = countProvider?.(room.type);

          return (
            <button
              onClick={() => onTentChange(room.type)}
              key={key}
              className="relative flex max-w-[400px] h-16 w-1/5 sm:min-h-24 md:min-h-28"
            >
              <img
                src={room.src}
                alt={room.alt}
                className={clsx(
                  'w-full pointer-events-none select-none overflow-x-clip h-full object-cover',
                  tent === room.type ? 'opacity-100' : 'opacity-50',
                )}
              />
              <span className="hidden sm:block bg-white absolute left-1/2 -translate-x-1/2 bottom-4 px-2 py-1 rounded-sm text-[10px] uppercase tracking-wider font-medium whitespace-nowrap">
                {getMessage(room.label)}
              </span>
              {count && count > 0 ? (
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white bg-black h-8 w-8 rounded-full flex items-center justify-center">
                  {count}
                </span>
              ) : null}
            </button>
          );
        })}
      </div>
      <div className="bg-black w-full flex justify-between px-5">
        <button
          onClick={() => {
            onTentChange(rooms[Math.max(0, activeIndex - 1) % rooms.length].type);
            analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
              screenSource: new URL(window.location.href).pathname,
              buttonTag: ButtonTag.PREV_ROOM,
            });
          }}
          type="button"
          className="text-white"
        >
          &larr;
        </button>
        <p className="text-white text-center py-3">
          <span className="uppercase tracking-widest text-xs block">
            {getMessage('camp67-reservation.form.step-1.room-viewer')}
          </span>
          <span className="font-bold">{getMessage(rooms[activeIndex].label)}</span>
        </p>
        <button
          onClick={() => {
            onTentChange(rooms[(activeIndex + 1) % rooms.length].type);
            analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
              screenSource: new URL(window.location.href).pathname,
              buttonTag: ButtonTag.PREV_ROOM,
            });
          }}
          type="button"
          className="text-white"
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};
